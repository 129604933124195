import React from "react";

import astra from "@/inline-assets/val-textwall-astra.svg";
import breach from "@/inline-assets/val-textwall-breach.svg";
import brimstone from "@/inline-assets/val-textwall-brimstone.svg";
import chamber from "@/inline-assets/val-textwall-chamber.svg";
import clove from "@/inline-assets/val-textwall-clove.svg";
import cypher from "@/inline-assets/val-textwall-cypher.svg";
import deadlock from "@/inline-assets/val-textwall-deadlock.svg";
import fade from "@/inline-assets/val-textwall-fade.svg";
import gekko from "@/inline-assets/val-textwall-gekko.svg";
import harbor from "@/inline-assets/val-textwall-harbor.svg";
import iso from "@/inline-assets/val-textwall-iso.svg";
import jett from "@/inline-assets/val-textwall-jett.svg";
import kayo from "@/inline-assets/val-textwall-kayo.svg";
import killjoy from "@/inline-assets/val-textwall-killjoy.svg";
import neon from "@/inline-assets/val-textwall-neon.svg";
import omen from "@/inline-assets/val-textwall-omen.svg";
import phoenix from "@/inline-assets/val-textwall-phoenix.svg";
import raze from "@/inline-assets/val-textwall-raze.svg";
import reyna from "@/inline-assets/val-textwall-reyna.svg";
import sage from "@/inline-assets/val-textwall-sage.svg";
import skye from "@/inline-assets/val-textwall-skye.svg";
import sova from "@/inline-assets/val-textwall-sova.svg";
import tejo from "@/inline-assets/val-textwall-tejo.svg";
import viper from "@/inline-assets/val-textwall-viper.svg";
import vyse from "@/inline-assets/val-textwall-vyse.svg";
import waylay from "@/inline-assets/val-textwall-waylay.svg";
import yoru from "@/inline-assets/val-textwall-yoru.svg";

const agentsTextWall = {
  astra,
  breach,
  brimstone,
  chamber,
  clove,
  cypher,
  deadlock,
  fade,
  gekko,
  harbor,
  iso,
  jett,
  kayo,
  killjoy,
  neon,
  omen,
  phoenix,
  raze,
  reyna,
  sage,
  skye,
  sova,
  tejo,
  waylay,
  viper,
  vyse,
  yoru,
};

export default function AgentTextWall({ agentKey, className }) {
  const TextWallComponent = agentsTextWall[agentKey];
  if (!TextWallComponent) return null;

  return <TextWallComponent className={className} />;
}
